/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --brown-medium: #302b28;
  --blue: #0026a0;
  --yellow: #d9b337;
  --green: #366355;
  --gray: #ebeaea;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "Great Vibes", cursive, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
