@media only screen and (max-width: 768px) {
  #nav-icon4 {
    width: 0rem;
    position: absolute;
    top: -2.3rem;
    margin: 3rem auto;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 0.2rem;
    width: 2rem;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon4 span:nth-child(1) {
    top: -2px;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
  }

  #nav-icon4 span:nth-child(2) {
    top: 4px;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
  }

  #nav-icon4 span:nth-child(3) {
    top: 10px;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
  }

  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -0.3rem;
    left: 0.1rem;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 1.1rem;
    left: 0.1rem;
  }
}
