.firstSection {
  /* background-image: url("../assets/section1.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.secondSection {
  /* background-image: url("../assets/section2.png"); */
  background-position: calc(100%) calc(100%);
  background-repeat: no-repeat;
  background-size: 50vw;
}
.thirdSection {
  background-image: url("../assets/section3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.sectionFive {
  background-image: url("../assets/section5.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}
.section1Book {
  width: 25vw;
  object-fit: scale-down;
}
.videoplayer {
  width: 90vw;
  height: 40vw;
}
.books {
  display: flex;
  justify-content: center;
  background-color: white;

  width: max-content;
  padding: 1.5vw;
  box-shadow: #cacaca 0 0 1vw;
}

.booktext {
  max-width: 15vw;
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
  font-style: italic;
}
.booktext2 {
  max-width: 15vw;
  font-size: 1.5vw;
  text-align: center;
  font-style: italic;
}
.bookimg {
  width: 15vw;
  height: 20vw;
  object-fit: scale-down;
}

@media only screen and (max-width: 768px) {
  .books {
    display: flex;
    justify-content: center;
    background-color: white;
    height: max-content;
    width: max-content;
    padding: 3vw 1.5vw;
  }
  .bookimg {
    width: 50vw;
    height: 55vw;
    object-fit: scale-down;
  }
  .booktext {
    max-width: 50vw;
    font-size: 5vw;
    font-weight: bold;
    font-style: italic;
  }
  .booktext2 {
    max-width: 50vw;
    font-size: 4vw;
    font-style: italic;
  }
  .section1Book {
    width: 36vw;
    object-fit: scale-down;
  }
  .videoplayer {
    height: 60vw;
    width: 90vw;
  }
}
@media only screen and (min-width: 768px) {
  .aboutauthor {
    width: 50vw;
  }
  .secondSection {
    /* background-image: url("../assets/section2.png"); */
    background-position: calc(100%) calc(100%);
    background-repeat: no-repeat;
    background-size: 30vw;
  }
}
.bottomBooks {
  max-height: 35vw;
}
